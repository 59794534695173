import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cores",
      "style": {
        "position": "relative"
      }
    }}>{`Cores`}</h1>
    <TabLinks items={[{
      title: 'Paleta',
      href: '/design-guidelines/color'
    }, {
      title: 'Utilização',
      href: '/design-guidelines/color/usage'
    }]} mdxType="TabLinks" />
    <p>{`Nossas paletas de cores foram criadas com foco na acessibilidade e facilidade de uso. Através delas, é possível
criar componentes acessíveis e até um modo `}<em parentName="p">{`dark`}</em>{`, invertendo os níveis de cor.`}</p>
    <h2 {...{
      "id": "consistencia",
      "style": {
        "position": "relative"
      }
    }}>{`Consistência`}</h2>
    <p>{`É possível alterar a cor primária dos componentes mantendo a proporção de contraste, porque todas as
escalas foram construídas com quantidades semelhantes de brilho em cada nível.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/color-consistency.png",
        "alt": "Consistência da cor nos botões: mesmo nível de brilho"
      }}></img></p>
    <h2 {...{
      "id": "acessibilidade",
      "style": {
        "position": "relative"
      }
    }}>{`Acessibilidade`}</h2>
    <p>{`As escalas são divididas em 10 níveis: as cores de 10 a 40 têm uma garantia contrastante de > 4,5: 1 em
fundos brancos, enquanto os de 70 a 100 têm a mesma garantia em fundos escuros. Dessa forma, nossos componentes respeitam as proporções de contraste adequadas para elementos textuais e interativos e elementos não interativos, de acordo com qualquer paleta.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/color-contrast.png",
        "alt": "Proporções de contraste de cores"
      }}></img></p>
    <h2 {...{
      "id": "modo-dark",
      "style": {
        "position": "relative"
      }
    }}>{`Modo Dark`}</h2>
    <p>{`Todas as paletas foram construídas para funcionar de maneira invertida. Para aplicar o modo Dark basta inverter as cores
do centro da escala, o que é 50 se torna 60, o que é 40 se torna 70 e assim vai. Para um exemplo prático, basta clicar na lâmpada em nosso cabeçalho!`}</p>
    <p><img parentName="p" {...{
        "src": "/image/color-darkui.png",
        "alt": "Escala de cinza invertida"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      